@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Lato', sans-serif; /* Sostituisci 'Nome del Font' con il nome del font che hai scelto */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

